<template>
  <div class="pl-2 pr-4">

    <gallery-banner v-if="!assetsLoading" :banner="selectedStyleGuideBanner" />

    <!-- Top Pagination and Controls -->
    <template v-if="!assetsLoading && assetsList && assetsList.data.length">
      <div class="text-center mb-5">
        <base-pagination
          class="inline-block"
          :model-value="assetsPage"
          :total-pages="assetsList.pageCount"
          @update:modelValue="handleAssetsPage($event)"
        />
      </div>
      <div class="relative">
        <div class="flex absolute right-0 -top-13">
          <base-button
            variant="btn-primary"
            class="mr-2"
            text="Add Page to Basket"
            :disabled="currentPageAddedToBasket"
            disabled-class="opacity-75 cursor-not-allowed"
            @click="addPageToBasket()"
          />
          <display-limit-selector
            v-model="assetsLimit"
            :options="limitOptions"
            variant="select-filled-red"
          />
        </div>
      </div>
    </template>


    <!-- Assets -->
    <div v-if="assetsLoading" class="text-center w-full pt-24 h-full sm:pt-28 md:pt-36 lg:pt-52">
      <base-svg
        class="h-4 w-4 mr-1 text-primary-red inline-block"
        src="icons/circleSpinner.svg"
        tag="span"
      />
      Loading ...
    </div>
    <div v-else-if="assetsList.data && assetsList.data.length" class="flex flex-wrap">
      <div
        v-for="(asset, assetIndex) in assetsList.data"
        :key="'card' + assetIndex"
        class="w-1/2 pr-8 pb-6 xs:w-1/4 lg:w-1/5 2xl:w-1/6 max-w-full h-auto cursor-pointer rounded-sm"
      >
        <gallery-asset-card
          :asset="asset"
          @click="openAssetDetails(asset)"
        />
      </div>
    </div>
    <div v-else class="text-center w-full py-52 sm:py-28 md:py-36 lg:py-52">
      No asset found that matched your search.<br/>
      Please note search results will be limited to assets that fall within your grant of rights.<br/>
      Please contact yout contract administrator for more informartion.
    </div>


    <!-- Bottom Pagination and Controls -->
    <template v-if="!assetsLoading && assetsList && assetsList.data.length > 12">
      <div class="text-center mb-5">
        <base-pagination
          class="inline-block"
          :model-value="assetsPage"
          :total-pages="assetsList.pageCount"
          @update:modelValue="handleAssetsPage($event)"
        />
      </div>
      <div class="relative">
        <div class="flex absolute right-0 -top-13">
          <base-button
            variant="btn-primary"
            class="mr-2"
            text="Add Page to Basket"
            :disabled="currentPageAddedToBasket"
            disabled-class="opacity-75 cursor-not-allowed"
            @click="addPageToBasket()"
          />
          <base-button
            variant="btn-primary"
            text="Go to top"
            @click="scrollToTop($event)"
          />
        </div>
      </div>
    </template>


    <!-- assets details modal -->
    <style-guide-asset-details-modal
      v-model="showAssetsDetailsModal"
      :asset-details="selectedAssetDetails"
    />

  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';
import useBasket from '@/hooks/basket.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { SUCCESS, ERROR, ASSET_ADDED_TO_BASKET_SUCCESSFULLY, ASSET_ADDED_TO_BASKET_FAILED } from '@/constants/alerts.js';

export default {
    name: 'GalleryAssets',

    components: {
        GalleryBanner: defineAsyncComponent(() => import('./GalleryBanner.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        GalleryAssetCard: defineAsyncComponent(() => import('./GalleryAssetCard.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        DisplayLimitSelector: defineAsyncComponent(() => import('@/components/DisplayLimitSelector.vue')),
        StyleGuideAssetDetailsModal: defineAsyncComponent(() => import('@/components/StyleGuideAssetDetailsModal.vue')),
        BaseSvg
    },

    setup () {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const { searchParams, assetsList, fetchAssetsList, assetsLoading } = useStyleGuideAssets();

        const selectedStyleGuide = computed(() => store.getters['styleGuideTree/getSelectedStyleGuide']);
        const selectedStyleGuideBanner = computed(() => store.getters['styleGuideTree/getStyleGuideBanner']);
        const searchInitiated = computed(() => store.getters['assetsSearch/getSearchInitiated']);

        const assetsPage = computed(() => store.getters['assets/getSearchPage']);
        const assetsLimit = ref(store.getters['assets/getSearchLimit']);
        const limitOptions = ref([
            '25',
            '50',
            '75',
            '100'
        ]);
        const handleAssetsPage = (evt) => {
            store.dispatch('assets/setSearchPage', evt);
            // assetsPage.value = evt;
            handleAssets();
        };
        watch(
            () => assetsLimit.value,
            () => {
                store.dispatch('assets/setSearchLimit', parseInt(assetsLimit.value));
                store.dispatch('assets/setSearchPage', 1);
                // assetsPage.value = 1;
                handleAssets();
            }
        );
        const handleAssets = () => {
            fetchAssetsList({ params: { ...searchParams.value } });
        };

        // asset details logic
        const showAssetsDetailsModal = ref(false);
        const selectedAssetDetails = ref({});
        const openAssetDetails = (asset) => {
            selectedAssetDetails.value = asset;
            showAssetsDetailsModal.value = true;
        };

        // basket logic
        const { addToBasket } = useBasket();
        const currentPageAddedToBasket = computed(() => assetsList.value && assetsList.value.data ? (assetsList.value.data.filter(asset => asset.addedToBasket).length === assetsList.value.data.length) : false);

        const addPageToBasket = () => {
            try {
                const assetListId = assetsList.value.data.map(asset => {
                    return asset.originalFileId;
                });
                const body = {
                    ids: assetListId
                };
                addToBasket(body);
                store.dispatch('assets/updateAssetsList', assetsList.value.data.map(asset => {
                    return {
                        ...asset,
                        addedToBasket: true
                    };
                }));
                setTimeout(() => {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ASSET_ADDED_TO_BASKET_SUCCESSFULLY
                    });
                }, 1000);
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: ASSET_ADDED_TO_BASKET_FAILED
                });
            }
        };

        const scrollToTop = (tmpEvent) => {
            tmpEvent.currentTarget.parentElement.parentElement.parentElement.parentElement.scrollTo(0, 0);
            // window.scrollTo(0, 0);
        };

        return {
            assetsPage,
            assetsLimit,
            limitOptions,
            assetsList,
            assetsLoading,
            selectedStyleGuide,
            searchInitiated,
            handleAssetsPage,
            showAssetsDetailsModal,
            openAssetDetails,
            selectedAssetDetails,
            addPageToBasket,
            currentPageAddedToBasket,
            scrollToTop,
            selectedStyleGuideBanner
        };
    }
};
</script>
